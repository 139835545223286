import { Tabs } from 'antd';
import { useEffect } from 'react';
import GiftPage from './GiftPage';
import RedeemPage from './RedeemPage';

const { TabPane } = Tabs;

const RedeemsPage = () => {
  useEffect(() => {}, []);

  return (
    <>
      <Tabs centered destroyInactiveTabPane={true}>
        <TabPane tab='DISCOVER' key='DISCOVER'>
          <GiftPage />
        </TabPane>
        <TabPane tab='HISTORY' key='HISTORY'>
          <RedeemPage />
        </TabPane>
      </Tabs>
    </>
  );
};

export default RedeemsPage;
