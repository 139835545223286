import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import AppCard from '../../../../@crema/core/AppCard';
import './index.style.less';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const PrevBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <i className='wc-icon icon-icon_left'></i>
    </div>
  );
};

const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <i className='wc-icon icon-icon_right'></i>
    </div>
  );
};

const CityInfo = (props) => {
  const { cityData, onClick } = props;

  return (
    <AppCard heightFull className='no-card-space' style={{ boxShadow: 'none' }}>
      <Slider
        className='city-info-slide-card'
        {...settings}
        autoplay={true}
        prevArrow={<PrevBtn />}
        nextArrow={<NextBtn />}>
        {cityData.map((city) => {
          return (
            <div
              key={city.id}
              className={`city-info-slide ${!!city.link && 'btn-pointer'}`}
              onClick={() => onClick?.(city)}>
              <img
                className='city-info-slide-img'
                src={city.image}
                alt='building'
              />
              <div className='city-info-slide-img-content'>
                <div className='city-info-slide-img-content-action'></div>
              </div>
            </div>
          );
        })}
      </Slider>
    </AppCard>
  );
};

export default CityInfo;

CityInfo.defaultProps = {
  cityData: [],
};

CityInfo.propTypes = {
  cityData: PropTypes.array,
};
