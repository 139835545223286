import { Layout } from 'antd';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FooterType, LayoutType } from '../../../../shared/constants/AppEnums';
import { AppContentView } from '../../../index';
import { useLayoutContext } from '../../../utility/AppContextProvider/LayoutContextProvider';
import AppFooter from '../components/AppFooter';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';
import './index.style.less';

const HorHeaderFixed = () => {
  const [isVisible, setVisible] = useState(false);
  const { footer, footerType, layoutType } = useLayoutContext();
  const location = useLocation();

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (layoutType === LayoutType.FRAMED) {
      document.body.classList.add('framedHorHeaderFixedLayout');
    } else {
      document.body.classList.remove('framedHorHeaderFixedLayout');
    }
  }, [layoutType]);

  return (
    <Layout
      className={clsx('app-layout-hor-header-fixed', {
        appMainFooter: footer && footerType === FooterType.FLUID,
        appMainFixedFooter: footer && footerType === FooterType.FIXED,
      })}>
      <AppSidebar visible={isVisible} onClose={onClose} />
      <AppHeader showDrawer={showDrawer} />
      <Layout className='app-layout-hor-header-fixed-main'>
        <div
          className={
            location.pathname.startsWith('/home') ||
            location.pathname.startsWith('/event/') ||
            location.pathname.startsWith('/network/community/') ||
            location.pathname.startsWith('/article/')
              ? 'home-container'
              : 'container'
          }>
          <AppContentView />
          <AppFooter />
        </div>
      </Layout>
    </Layout>
  );
};

export default HorHeaderFixed;
