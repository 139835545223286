import React from 'react';
import LandingPage from '../home/LandingPage';

const Signin = React.lazy(() => import('./Signin'));
const VerifyAccount = React.lazy(() => import('./VerifyAccount'));
const VerifiedAccount = React.lazy(() => import('./VerifiedAccount'));
const Signup = React.lazy(() => import('./Signup'));
const ForgotPassword = React.lazy(() => import('./ForgotPassword'));

const SignInWithSocialRedirect = React.lazy(
  () => import('./Signin/SignInWithSocialRedirect'),
);

const ResetPassword = React.lazy(() => import('./ResetPassword'));

export const authRouteConfig = [
  {
    path: '/index.html',
    element: <LandingPage />,
  },
  {
    path: '/signin',
    element: <Signin />,
  },
  {
    path: '/auth/google/redirect',
    element: <SignInWithSocialRedirect />,
  },
  {
    path: '/auth/facebook/redirect',
    element: <SignInWithSocialRedirect />,
  },
  {
    path: '/auth/linkedin/redirect',
    element: <SignInWithSocialRedirect />,
  },
  {
    path: '/signup',
    element: <Signup />,
  },
  {
    path: '/forget-password',
    element: <ForgotPassword />,
  },
  {
    path: '/auth/verify-email',
    element: <VerifyAccount />,
  },
  {
    path: '/auth/account/verified',
    element: <VerifiedAccount />,
  },
  {
    path: '/reset-password/verify',
    element: <ResetPassword />,
  },
];
