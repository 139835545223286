import React from 'react';
import { RoutePermittedRole } from '../../shared/constants/AppEnums';

const VoicePage = React.lazy(() => import('./VoicePage'));
const VoiceDetailPage = React.lazy(() => import('./VoiceDetailPage'));

export const voiceChildPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/voice/:id',
    element: <VoiceDetailPage />,
  },
];

export const voiceRootPagesConfigs = [
  {
    isContainer: true,
    path: '/voice',
    element: <VoicePage />,
  },
];
