import React from 'react';
import RewardPage from './UserProfile/PersonalInfo/RewardPage';

const UserProfile = React.lazy(() => import('./UserProfile'));
const AccountSetup = React.lazy(() => import('./UserProfile/AccountSetup'));
const ChangePasswordPage = React.lazy(
  () => import('./UserProfile/ChangePasswordPage'),
);

const ConfigureMentee = React.lazy(
  () => import('./UserProfile/ConfigureMentee'),
);

const MenteeTerm = React.lazy(() => import('./UserProfile/MenteeTerm'));
const SelectProfile = React.lazy(() => import('./UserProfile/SelectProfile'));
const UserPreferencePage = React.lazy(
  () => import('./UserProfile/UserPreference'),
);

export const profileConfig = [
  {
    path: '/account-setup',
    element: <AccountSetup />,
  },
  {
    path: '/my-profile',
    element: <UserProfile />,
  },
  {
    path: 'change-password',
    element: <ChangePasswordPage />,
  },
  {
    path: 'user-preference',
    element: <UserPreferencePage />,
  },
  {
    path: 'level-request',
    element: <ConfigureMentee />,
  },
  {
    path: 'select-profile',
    element: <SelectProfile />,
  },
  {
    path: 'mentee-term-conditions',
    element: <MenteeTerm />,
  },
  {
    path: 'reward',
    element: <RewardPage />,
  },
];
