import { Col, Row } from 'antd';
import './index.style.less';

const Footer = ({ fixed }) => {
  const year = new Date().getFullYear();
  return (
    <Row className={`footer ${fixed && 'footer-fixed'}`}>
      <Col md={{ span: 20, offset: 2 }} xs={24} className='mb-0 text-center'>
        <span>© Copyright {year} MentoringSG. All rights reserved.</span>
      </Col>
    </Row>
  );
};

export default Footer;
