import { Button, Card, Col, Image, Row, Typography } from 'antd';
import './index.style.less';
import { useAuthUser } from '../../../../@crema/utility/AuthHooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';

const SetUpProfileReminder = () => {
  const navigate = useNavigate();
  const { user } = useAuthUser();

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!user) {
      return;
    }
    const doNotRemind = localStorage.getItem('doNotRemindSetUpProfile');
    if (!doNotRemind) {
      setShow(true);
    }
  }, [user]);

  const onDoNotRemind = () => {
    localStorage.setItem('doNotRemindSetUpProfile', 'true');
    setShow(false);
  };

  if (!user) {
    return null;
  }

  if (user.profile?.profileLifeStages?.length) {
    return null;
  }

  if (!show) {
    return null;
  }

  return (
    <Card className='setup-profile-reminder'>
      <Row>
        <Col md={16} xs={24} className='setup-profile-reminder__content'>
          <Typography.Title className='setup-profile-reminder__title' level={3}>
            Complete Your Onboarding!
          </Typography.Title>
          <Typography color='#b6d1ed' style={{ fontSize: 20 }}>
            We're gearing up to enhance your experience. Complete your
            onboarding form so we can curate recommendations that match your
            vibe!
          </Typography>
          <Button
            className='btn-complete'
            onClick={() => navigate('/account-setup')}>
            COMPLETE ONBOARDING
          </Button>
          <br />
          <Button
            type='link'
            className='btn-do-not-remind'
            onClick={onDoNotRemind}>
            DON'T REMIND AGAIN
          </Button>
        </Col>
        <Col md={8} xs={24}>
          <Image
            width={'100%'}
            preview={false}
            src='https://i.ibb.co/BrwF0XY/ilustracion-tutoria-concepto-personas-sofa-motivacion-plana-pequena-maestro-desarrollo-educativo-emp.png'
          />
        </Col>
      </Row>
      <Button type='link' className='btn-close' onClick={onDoNotRemind}>
        <CloseOutlined color='white' />
      </Button>
    </Card>
  );
};

export default SetUpProfileReminder;
