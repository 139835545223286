import { Avatar, Dropdown, List, Space } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useSidebarContext } from '../../../../utility/AppContextProvider/SidebarContextProvider';
import { useThemeContext } from '../../../../utility/AppContextProvider/ThemeContextProvider';
import { useAuthMethod, useAuthUser } from '../../../../utility/AuthHooks';
import './index.style.less';

const UserInfo = ({ hasColor }) => {
  const { themeMode } = useThemeContext();
  const { logout } = useAuthMethod();
  const { user } = useAuthUser();
  const navigate = useNavigate();
  const { sidebarColorSet } = useSidebarContext();
  const { isSidebarBgImage } = useSidebarContext();

  const getUserAvatar = () => {
    if (user.displayName) {
      return user.displayName.charAt(0).toUpperCase();
    }
    if (user.email) {
      return user.email.charAt(0).toUpperCase();
    }
  };

  const [isSocialAccount, setIsSocialAccount] = useState(false);
  useEffect(() => {
    const isSocialAccount = !!localStorage.getItem('isSocialAccount');
    setIsSocialAccount(isSocialAccount);
  }, []);

  const menu = (
    <List className='dropdown-list'>
      <List.Item
        onClick={() => navigate('/my-profile')}
        style={{ marginBottom: 4 }}>
        <Space size={12}>
          <i className='wc-icon icon-ic_profile' /> MY PROFILE
        </Space>
      </List.Item>
      <List.Item
        onClick={() => navigate('/user-preference')}
        style={{ marginBottom: 4 }}>
        <Space size={12}>
          <i className='wc-icon icon-ic_noti' /> PREFERENCES
        </Space>
      </List.Item>
      {!isSocialAccount && (
        <List.Item
          onClick={() => navigate('/change-password')}
          style={{ marginBottom: 4 }}>
          <Space size={12}>
            <i className='wc-icon icon-ic_passsword' /> CHANGE PASSWORD
          </Space>
        </List.Item>
      )}
      <List.Item
        onClick={() => navigate('/support')}
        style={{ marginBottom: 4 }}>
        <Space size={12}>
          <i className='wc-icon icon-ic_support' /> SUPPORT
        </Space>
      </List.Item>
      <List.Item onClick={() => logout()} style={{ marginBottom: 4 }}>
        <Space size={12}>
          <i className='wc-icon icon-ic_log-out' /> LOGOUT
        </Space>
      </List.Item>
    </List>
  );

  if (!user) {
    return null;
  }

  return (
    <>
      {hasColor ? (
        <div
          style={{
            backgroundColor: isSidebarBgImage
              ? ''
              : sidebarColorSet.sidebarHeaderColor,
            color: sidebarColorSet.sidebarTextColor,
          }}
          className={clsx('cr-user-info cr-user-info-hasColor', {
            light: themeMode === 'light',
          })}>
          <Dropdown
            className='user-profile-dropdown'
            overlay={menu}
            trigger={['hover']}
            placement='bottomRight'
            overlayStyle={{
              zIndex: 1052,
              minWidth: 150,
            }}>
            <a className='cr-user-info-inner ant-dropdown-link'>
              {user?.photoURL ? (
                <Avatar className='cr-user-info-avatar' src={user.photoURL} />
              ) : (
                <Avatar className='cr-user-info-avatar'>
                  {getUserAvatar()}
                </Avatar>
              )}
              <span className='cr-user-info-content'>
                <span className='cr-user-name-info'>
                  <h3
                    className={clsx('cr-user-name text-truncate', {
                      light: themeMode === 'light',
                    })}>
                    {user.displayName ? user.displayName : 'USER '}
                  </h3>
                  <span className='cr-user-arrow'>
                    <FaChevronDown />
                  </span>
                </span>
                <span className='cr-user-designation text-truncate'>
                  {user.accountType}
                </span>
              </span>
            </a>
          </Dropdown>
        </div>
      ) : (
        <div
          className={clsx('cr-user-info', {
            light: themeMode === 'light',
          })}>
          <Dropdown
            className='user-profile-dropdown'
            overlay={menu}
            trigger={['hover']}
            placement='bottomRight'
            overlayStyle={{
              zIndex: 1052,
              minWidth: 150,
              background: '#fff',
              borderRadius: 4,
            }}>
            <a className='cr-user-info-inner ant-dropdown-link'>
              {user?.photoURL ? (
                <Avatar className='cr-user-info-avatar' src={user.photoURL} />
              ) : (
                <Avatar className='cr-user-info-avatar'>
                  {getUserAvatar()}
                </Avatar>
              )}
              <span className='cr-user-info-content'>
                <span className='cr-user-name-info'>
                  <h3
                    className={clsx('cr-user-name text-truncate', {
                      light: themeMode === 'light',
                    })}>
                    {user.displayName ? user.displayName : 'User '}
                  </h3>
                  <span className='cr-user-arrow'>
                    <FaChevronDown />
                  </span>
                </span>
                <span className='cr-user-designation text-truncate'>
                  {user.accountType}
                </span>
              </span>
            </a>
          </Dropdown>
        </div>
      )}
    </>
  );
};

export default UserInfo;

UserInfo.propTypes = {
  hasColor: PropTypes.bool,
};
