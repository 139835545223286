import { Space } from 'antd';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import CommunityCard from '../../../search/SearchPage/CommunityCard';
import { GroupCardLoading } from './GroupCardLoading';
import './index.style.less';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  autoplaySpeed: 5000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const FeaturedGroups = (props) => {
  const { listGroups, loading, onRefresh } = props;

  return (
    <Slider
      className='slider-card'
      {...settings}
      autoplay={true}
      arrows={false}>
      {!listGroups.length &&
        loading &&
        [1, 2, 3].map((x) => (
          <Space key={x}>
            <GroupCardLoading />
          </Space>
        ))}
      {listGroups.map((group) => (
        <CommunityCard key={group.id} group={group} onRefresh={onRefresh} />
      ))}
      {listGroups.length < 3 && !loading
        ? [0, 1, 2].slice(0, 3 - listGroups.length).map((i) => <div key={i} />)
        : undefined}
    </Slider>
  );
};

export default FeaturedGroups;

FeaturedGroups.defaultProps = {
  listGroups: [],
};

FeaturedGroups.propTypes = {
  listGroups: PropTypes.array,
};
