import React from 'react';
import { Provider } from 'react-redux';
import './shared/styles/crema.less';
import {
  AppContextProvider,
  AppLayout,
  AppLocaleProvider,
  AppThemeProvider,
  AuthRoutes,
} from './@crema';
import configureStore from './redux/store';
import { BrowserRouter } from 'react-router-dom';
import './@crema/services/index';
import JWTAuthAuthProvider from './@crema/services/auth/jwt-auth/JWTAuthProvider';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ConfigProvider } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';

const store = configureStore();

const App = () => (
  <AppContextProvider>
    <Provider store={store}>
      <GoogleReCaptchaProvider
        scriptProps={{
          async: false,
          defer: false,
          appendTo: 'head',
          nonce: undefined,
        }}
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
        <AppThemeProvider>
          <ConfigProvider locale={en}>
            <AppLocaleProvider>
              <BrowserRouter>
                <JWTAuthAuthProvider>
                  <AuthRoutes>
                    <AppLayout />
                  </AuthRoutes>
                </JWTAuthAuthProvider>
              </BrowserRouter>
            </AppLocaleProvider>
          </ConfigProvider>
        </AppThemeProvider>
      </GoogleReCaptchaProvider>
    </Provider>
  </AppContextProvider>
);

export default App;
