import {
  ADD_NEW_MESSAGE,
  DELETE_MESSAGE,
  DELETE_USER_MESSAGES,
  EDIT_MESSAGE,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_USER_MESSAGES,
  SELECT_USER,
  SHOW_MESSAGE,
  TOGGLE_CHAT_DRAWER,
} from '../../shared/constants/ActionTypes';
import { appIntl } from '../../@crema/utility/helper/Utils';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';

const Api = jwtAxios;

export const onSelectUser = (user) => {
  return (dispatch) => {
    dispatch({ type: SELECT_USER, payload: user });
  };
};

export const onToggleChatDrawer = () => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_CHAT_DRAWER });
  };
};
