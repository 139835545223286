import { UserOutlined } from '@ant-design/icons';
import {
  AutoComplete,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Spin,
  TimePicker,
} from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { postDataApi } from '@crema/utility/APIHooks';
import { useAuthUser } from '@crema/utility/AuthHooks';
import './index.style.less';
import SearchItem from './SearchItem';

const ScheduleModal = ({ mentees, initConnection, visible, onCancel }) => {
  const [options, setOptions] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [meetingType, setMeetingType] = useState('Online');
  const [selected, setSelected] = useState({});
  const [form] = Form.useForm();
  const { user } = useAuthUser();
  const [loading, setLoading] = useState(false);

  const { confirm } = Modal;

  const showConfirm = () => {
    confirm({
      title: 'LEAVE CONFIRMATION',
      content:
        'Oops! Your session has not been scheduled. Are you sure you want to leave?',
      okText: 'CONFIRM',
      cancelText: 'DISCARD',
      onOk() {
        onCancel?.(false);
      },
      onCancel() {},
    });
  };

  const onCreateSchedule = async (values) => {
    if (!values.isAgreed) {
      message.error('Please tick the Term & Conditions');
      return;
    }

    if (values.timeFrom > values.timeTo) {
      message.error('Time To must be greater than Time From.');
      return;
    }

    const params = {
      userSchedules: [{ userId: user.id }],
      scheduleInvitations: [
        { toId: user.id !== selected.toId ? selected.toId : selected.fromId },
      ],
      name: values.name,
      meetingType: values.meetingType,
      location: values.location,
      meetingUrl: values.meetingUrl,
      connectionId: values.connectionId,
      startTime: moment(
        moment(values.date).format('YYYY/MM/DD') +
          ' ' +
          moment(values.timeFrom).format('HH:mm:ss'),
        'YYYY/MM/DD HH:mm:ss',
      ).toISOString(),
      endTime: moment(
        moment(values.date).format('YYYY/MM/DD') +
          ' ' +
          moment(values.timeTo).format('HH:mm:ss'),
        'YYYY/MM/DD HH:mm:ss',
      ).toISOString(),
    };
    setLoading(true);
    const res = await postDataApi('/schedules', params, {
      fetchSuccess: () => {
        message.success(`Your appointment has been added!`);
      },
    });
    setLoading(false);
    if (res) {
      onCancel(true);
    }
  };

  const listConnection = () => {
    setOptions(
      mentees.map((e) => ({
        name: e.fromId !== user.id ? e.from?.name : e.to?.name,
        value: e.id,
        label: <SearchItem user={e} />,
      })),
    );
  };

  useEffect(() => {
    listConnection();
  }, []);

  useEffect(() => {
    if (initConnection) {
      onSelect(initConnection.id);
    }
  }, [initConnection]);

  const onSelect = (v) => {
    const selected = mentees.find((e) => e.id === v);
    setSelected(selected);
    form.setFieldsValue({ connectionId: v });
    setIsSelected(selected);
  };

  const onRemove = () => {
    setIsSelected(false);
    form.setFieldsValue({ connectionId: undefined });
  };

  return (
    <Spin spinning={loading}>
      <Modal
        title='SCHEDULE A SESSION'
        open={visible}
        onCancel={() => onCancel(false)}
        destroyOnClose
        width={600}
        closable={false}
        footer={
          <Row gutter={16}>
            <Col xs={8} style={{ marginBottom: 12 }}>
              <Button
                key='back'
                type='primary'
                ghost
                block
                onClick={showConfirm}>
                DISCARD
              </Button>
            </Col>
            <Col xs={16} style={{ marginBottom: 12 }}>
              <Button
                block
                key='submit'
                type='primary'
                onClick={() => {
                  form
                    .validateFields()
                    .then((values) => {
                      onCreateSchedule(values);
                    })
                    .catch(() => {});
                }}>
                SEND
              </Button>
            </Col>
          </Row>
        }>
        <Form
          form={form}
          name='voice-create-form'
          onFinish={onCreateSchedule}
          initialValues={{
            isAgreed: true,
            meetingType: 'Online',
          }}
          autoComplete='off'
          layout='vertical'>
          <Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item
                label='Session Name'
                name='name'
                rules={[{ required: true, message: 'Enter your sesson name' }]}>
                <Input placeholder='Enter session name'></Input>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label='Date'
                name='date'
                rules={[{ required: true, message: 'Choose date' }]}>
                <DatePicker
                  placeholder='mm/dd/yyyy'
                  disabledDate={(current) => {
                    return current && current < moment().startOf('day');
                  }}
                  format='MM/DD/YYYY'></DatePicker>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label='Time From'
                name='timeFrom'
                rules={[{ required: true, message: 'Pick Time from' }]}>
                <TimePicker
                  placeholder='hh:mm'
                  format='HH:mm'
                  minuteStep={15}></TimePicker>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label='Time To'
                name='timeTo'
                rules={[{ required: true, message: 'Pick Time to' }]}>
                <TimePicker
                  placeholder='hh:mm'
                  format='HH:mm'
                  minuteStep={15}></TimePicker>
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item
                label='Type'
                name='meetingType'
                rules={[{ required: true }]}>
                <Radio.Group
                  options={[
                    { label: 'Online', value: 'Online' },
                    { label: 'In-person', value: 'In-person' },
                  ]}
                  optionType='button'
                  buttonStyle='solid'
                  defaultValue={'Online'}
                  value={meetingType}
                  onChange={(e) => setMeetingType(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              {meetingType === 'Online' ? (
                <Form.Item
                  label='Meeting URL'
                  name='meetingUrl'
                  rules={[{ required: true }]}>
                  <Input placeholder='Enter meeting url'></Input>
                </Form.Item>
              ) : (
                <Form.Item
                  label='Location'
                  name='location'
                  rules={[{ required: true }]}>
                  <Input placeholder='Enter meeting location'></Input>
                </Form.Item>
              )}
            </Col>
            <Col md={24} xs={24}>
              <Form.Item
                label='Send Invitation To'
                name='connectionId'
                rules={[{ required: true, message: 'Select a connection' }]}>
                {!isSelected && (
                  <AutoComplete
                    allowClear
                    suffixIcon={<UserOutlined />}
                    options={options}
                    onSelect={onSelect}
                    filterOption={(inputValue, option) =>
                      option.name
                        ?.toUpperCase()
                        .indexOf(inputValue?.toUpperCase()) !== -1
                    }>
                    <Input.Search
                      style={{ borderRadius: 15 }}
                      placeholder='Search to add connection'
                      size='large'></Input.Search>
                  </AutoComplete>
                )}
                {isSelected && (
                  <SearchItem
                    user={isSelected}
                    isSelected={true}
                    onRemove={onRemove}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name='isAgreed'
            valuePropName='checked'
            style={{ marginBottom: 0 }}
            wrapperCol={{ offset: 6, span: 16 }}>
            <Checkbox>
              I agree to{' '}
              <Link target={'_blank'} to='/terms-and-conditions'>
                Terms &amp; Conditions.
              </Link>
            </Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    </Spin>
  );
};

export default ScheduleModal;
