import React from 'react';

const ArticlePage = React.lazy(() => import('./ArticlePage'));
const ArticleDetailPage = React.lazy(() => import('./ArticleDetailPage'));
const ArticleEdit = React.lazy(() => import('./ArticleDetailPage/ArticleEdit'));
const TermAndCondition = React.lazy(
  () => import('./ArticleDetailPage/TermAndCondition'),
);
const ContentPage = React.lazy(() => import('./ArticleDetailPage/ContentPage'));

export const articlePagesConfigs = [
  {
    path: '/article',
    element: <ArticlePage />,
  },
  {
    path: '/terms-and-conditions',
    element: <TermAndCondition />,
  },
  {
    path: '/guidelines',
    element: <TermAndCondition />,
  },
  {
    path: '/p/:category',
    element: <ContentPage />,
  },
  {
    path: '/article/:id/edit',
    element: <ArticleEdit />,
  },
  {
    path: '/article/:id',
    element: <ArticleDetailPage />,
  },
];
