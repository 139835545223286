import { Col, Row, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { getDataApi } from '../../../../@crema/utility/APIHooks';
import GiftDetailModal from './GiftDetailModal';
import RedeemItem from './RedeemItem';

const RedeemPage = ({ type }) => {
  const [loading, setLoading] = useState(false);
  const [detailModalVisible, setDetailModalVisible] = useState(null);

  const [gifts, setGifts] = useState([]);

  const getGifts = async () => {
    if (type) {
      return;
    }
    setLoading(true);
    const res = await getDataApi('/redeems', { take: 1000000 });
    if (res?.data) {
      setGifts(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getGifts();
  }, []);

  return (
    <>
      <Spin spinning={loading}>
        <Row gutter={16} className='d-100'>
          {gifts.map((x) => (
            <Col md={8} xs={24} key={x.id}>
              <RedeemItem
                data={x}
                connection={x.connection}
                hasMessage={true}
                visitProfile={false}
                hasMore={true}
                connectionId={x.connectionId}
                hasRedeem={false}
                onImageClick={() => setDetailModalVisible(x.gift)}
              />
            </Col>
          ))}
        </Row>
      </Spin>
      {detailModalVisible && (
        <GiftDetailModal
          gift={detailModalVisible}
          onCancel={() => setDetailModalVisible(null)}
          onCreate={() => setDetailModalVisible(null)}
        />
      )}
    </>
  );
};

export default RedeemPage;
