import { Button, Card, Row, Space, Tag } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { postDataApi } from '@crema/utility/APIHooks';
import { MessageButton } from '../../search/SearchPage/InfoCard';
import { useAuthUser } from '@crema/utility/AuthHooks';
import './index.style.less';

const CommunityCard = ({ group, isMyGroup, onRefresh }) => {
  const [loading, setLoading] = useState(false);

  const { user } = useAuthUser();
  const navigate = useNavigate();

  const onJoinGroupClick = async () => {
    if (!user) {
      localStorage.setItem('navigation', `/network/community/${group.id}`);
      window.location.href = '/signin';
      return;
    }

    setLoading(true);
    const res = await postDataApi('/groups/requests', {
      groupId: group.id,
    });
    setLoading(false);

    if (res.data.isJoined) {
      navigate(`/network/community/${group.id}`);
      return;
    }

    onRefresh?.();
  };

  const isJoined = !!group.groupMembers?.length || isMyGroup;
  const isRequested = !!group.requestGroups?.filter(
    (x) => x.status === 'Pending',
  ).length;
  return (
    <Card className='community-card'>
      <div
        className='community-img community-hover-img'
        onClick={() => navigate(`/network/community/${group.id}`)}
        style={{
          backgroundImage: `url(${group.banner?.split(',')[0]})`,
        }}
      />
      <div className='community-body'>
        <Row className='d-space-between mb-8'>
          <Tag color='blue'>{group.industry?.name}</Tag>
          <span style={{ color: '#4d4d4d' }}>
            {group.totalMembers}
            <span className={`msg-icon ico2-ic_group`} />
          </span>
        </Row>
        <Link to={`/network/community/${group.id}`}>
          <Text className='community-title btn-link'>{group.name}</Text>
        </Link>
        <span className='community-desc'>{group.description}</span>
        <Space className='d-justify-center d-flex d-100'>
          {isJoined && <MessageButton group={group}></MessageButton>}
          {isRequested && (
            <Button type='primary' ghost>
              <i className='wc-icon icon-ic_check'></i>
              REQUESTED
            </Button>
          )}
          {!isJoined && !isRequested && (
            <Button loading={loading} onClick={onJoinGroupClick} type='link'>
              JOIN COMMUNITY
            </Button>
          )}
        </Space>
      </div>
    </Card>
  );
};

export default CommunityCard;
