import React, { useEffect } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import './index.style.less';

const AppScrollbar = ({ children, scrollToTop, className, ...others }) => {
  let _scrollBarRef = null;
  const { pathname } = useLocation();

  useEffect(() => {
    if (scrollToTop && _scrollBarRef) {
      _scrollBarRef._container.scrollTop = 0;
    }
  }, [_scrollBarRef, scrollToTop, pathname]);

  return (
    <PerfectScrollbar
      ref={(ref) => {
        _scrollBarRef = ref;
      }}
      {...others}
      className={className}>
      {children}
    </PerfectScrollbar>
  );
};

export default AppScrollbar;

AppScrollbar.defaultProps = {
  className: '',
  scrollToTop: true,
};

AppScrollbar.propTypes = {
  children: PropTypes.node.isRequired,
  scrollToTop: PropTypes.bool,
  className: PropTypes.string,
};
