import moment from 'moment';
import { authRole } from '../../shared/constants/AppEnums';

export const createRoutes = (routeConfigs) => {
  let allRoutes = [];
  routeConfigs.forEach((config) => {
    allRoutes = [...allRoutes, ...setRoutes(config)];
  });
  return allRoutes;
};

export const setRoutes = (config) => {
  let routes = [...config.routes];
  if (config.auth) {
    routes = routes.map((route) => {
      let auth = route.auth
        ? [...config.auth, ...route.auth]
        : [...config.auth];
      return { ...route, auth };
    });
  }

  return [...routes];
};
export const getTimeCalendar = (mode, value) => {
  const year = moment(value).year();
  const startWeekMonth = moment(value).startOf('isoWeek').format('MMM');
  const endWeekMonth = moment(value).endOf('isoWeek').format('MMM');
  const startWeek = moment(value).startOf('isoWeek').format('DD');
  const endWeek = moment(value).endOf('isoWeek').format('DD');
  if (mode === 'monthly') return `${startWeekMonth} ${year}`;
  if (startWeekMonth === endWeekMonth) {
    return `${startWeekMonth} ${startWeek}-${endWeek}, ${year}`;
  }
  return `${startWeekMonth} ${startWeek} - ${endWeekMonth} ${endWeek}, ${year}`;
};
export const getBreakPointsValue = (valueSet, breakpoint) => {
  if (typeof valueSet === 'number') return valueSet;
  switch (breakpoint) {
    case 'xs':
      return valueSet.xs;
    case 'sm':
      return valueSet.sm || valueSet.xs;
    case 'md':
      return valueSet.md || valueSet.sm || valueSet.xs;
    case 'lg':
      return valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs;
    default:
      return (
        valueSet.xl || valueSet.lg || valueSet.md || valueSet.sm || valueSet.xs
      );
  }
};

export const getFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  let k = 1024,
    dm = 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const multiPropsFilter = (products, filters, stringKey = 'title') => {
  const filterKeys = Object.keys(filters);
  return products.filter((product) => {
    return filterKeys.every((key) => {
      if (!filters[key].length) return true;
      // Loops again if product[key] is an array (for material attribute).
      if (Array.isArray(product[key])) {
        return product[key].some((keyEle) => filters[key].includes(keyEle));
      }
      console.log('key', key, filters[key], product[key]);
      if (key === stringKey) {
        return product[key].toLowerCase().includes(filters[key].toLowerCase());
      }
      return filters[key].includes(product[key]);
    });
  });
};

export const getCustomDateTime = (
  value = 0,
  unit = 'days',
  format = 'YYYY-MM-DD',
) => {
  if (value === 0) {
    return moment().format(format);
  } else {
    return moment().add(value, unit).format(format);
  }
};

export const getChatActiveTime = (latestTime) => {
  if (!latestTime) {
    return '';
  }
  const latest = moment(latestTime);
  const isDays = moment().startOf('days').diff(latest.startOf('days'), 'days');

  if (isDays >= 7) {
    return moment(latestTime).format('MM/DD/YYYY');
  }

  if (isDays > 1) {
    return `${isDays} days ago`;
  }

  const isSameDay = moment()
    .startOf('days')
    .isSame(latest.startOf('days'), 'day');
  if (isSameDay) {
    return moment(latestTime).format('HH:mm');
  }

  if (isDays > 0 && isDays <= 1) {
    return 'Yesterday';
  }

  return '';
};

export const getTimeAgo = (latestTime) => {
  if (!latestTime) {
    return '';
  }
  const latest = moment(latestTime).format('YYYY-MM-DD HH:mm:ss');
  const isMinutes = moment().diff(latest, 'm');
  const isHours = moment().diff(latest, 'hour');
  const isDays = moment().diff(latest, 'days');

  if (isDays > 7) {
    return moment(latest).format('MMMM DD, YYYY');
  }

  if (isDays > 0 && isDays <= 1) {
    return 'Yesterday';
  }

  const time = isDays
    ? `${isDays} ${isDays > 1 ? 'days' : 'day'}`
    : isHours
      ? `${isHours} ${isHours > 1 ? 'hours' : 'hour'}`
      : isMinutes > 1
        ? `${isMinutes || 1} mins`
        : `${1} min`;

  return `${time} ago`;
};

export const getOnlineTime = (latestTime) => {
  if (!latestTime) {
    return '';
  }
  const latest = moment(latestTime);
  const diffMinutes = moment().diff(latest, 'm');
  const diffHours = moment().diff(latest, 'hour');
  const diffDays = moment().diff(latest, 'days');

  if (diffDays > 14) {
    return `Online ${Math.floor(diffDays / 7)} weeks ago`;
  }

  if (diffDays > 7) {
    return `Online ${Math.floor(diffDays / 7)} week ago`;
  }

  if (diffMinutes < 5) {
    return 'Online';
  }

  const time = diffDays
    ? `${diffDays} ${diffDays > 1 ? 'days' : 'day'}`
    : diffHours
      ? `${diffHours} ${diffHours > 1 ? 'hours' : 'hour'}`
      : diffMinutes > 1
        ? `${diffMinutes || 1} minutes`
        : `${1} minute`;

  return `Online ${time} ago`;
};

export const getTimeRead = (latestTime) => {
  if (!latestTime) {
    return '';
  }
  const latest = moment(latestTime).format('YYYY-MM-DD HH:mm:ss');
  const isMinutes = moment().diff(latest, 'm');
  const isHours = moment().diff(latest, 'hour');
  const isDays = moment().diff(latest, 'days');
  const time = isDays
    ? `${isDays} ${isDays > 1 ? 'days' : 'day'}`
    : isHours
      ? `${isHours} ${isHours > 1 ? 'hours' : 'hour'}`
      : isMinutes > 1
        ? `${isMinutes || 1} mins`
        : `${1} min`;

  return `${time} read`;
};

export const getDateDisplay = (date) => {
  return moment(date).format('MMMM, DD YYYY');
};

export const getTimeAgoDisplay = (date) => {
  const isSameDay = moment(date).isSame(moment(), 'day');

  if (isSameDay) {
    return moment(date).format('HH:mm');
  }

  return moment(date).format('MMMM, DD HH:mm');
};

export const timeFromNow = (date) => {
  const timestamp = moment(date).format('X');
  const newDate = moment.unix(timestamp);
  return moment(newDate).fromNow();
};

export const isRequestSuccessful = (status) => status < 300;

export const checkPermission = (routeAuth, userRole) => {
  if (routeAuth === null || routeAuth === undefined) {
    return true;
  }

  if (userRole && Array.isArray(userRole)) {
    return routeAuth.some((r) => userRole.indexOf(r) >= 0);
  }

  if (routeAuth.length === 0) {
    return !userRole || userRole.length === 0;
  }
  if (userRole && Array.isArray(userRole) && Array.isArray(routeAuth)) {
    return routeAuth.some((r) => userRole.indexOf(r) >= 0);
  }
  return routeAuth.indexOf(userRole) >= 0;
};

export const generateUniqueID = () => {
  return `v1-${Date.now()}-${Math.floor(Math.random() * (9e12 - 1)) + 1e12}`;
};

export const getUserFromJwtAuth = (user) => {
  if (user)
    return {
      ...user,
      uid: user._id,
      displayName:
        user.name ||
        `${user.profile?.firstName || ''} ${user?.profile?.lastName || ''}`,
      email: user.email,
      photoURL: user.profile?.avatar,
      role: authRole.user,
    };
  return user;
};
