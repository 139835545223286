import { Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import EventCard from '../../../network/NetworkPage/EventCard';
import { EventCardLoading } from '../../../network/NetworkPage/EventCardLoading';
import './index.style.less';
import { useMediaQuery } from 'react-responsive';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  autoplaySpeed: 5000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const UpcomingEvents = (props) => {
  const { events, onRefresh, loading } = props;
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });

  if (loading) {
    return (
      <Row gutter={16} className='mt-16'>
        {(isMobile ? [1] : [1, 2, 3]).map((x) => (
          <Col xs={24} md={8} key={x}>
            <EventCardLoading />
          </Col>
        ))}
      </Row>
    );
  }

  if (!events?.length) {
    return (
      <Row
        className='d-justify-center d-align-items-center'
        style={{ minHeight: 140 }}>
        <Typography style={{ paddingTop: 48 }}>No upcomming events.</Typography>
      </Row>
    );
  }

  return (
    <Slider
      className='slider-card'
      {...settings}
      autoplay={true}
      arrows={false}>
      {events.map((event) => (
        <EventCard key={event.id} event={event} onRefresh={onRefresh} />
      ))}
      {events.length < 3 && !isMobile
        ? [0, 1, 2].slice(0, 3 - events.length).map((i) => <div key={i} />)
        : undefined}
    </Slider>
  );
};

export default UpcomingEvents;
