import { Avatar, Button, Col, Modal, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import Linkify from 'linkify-react';
import { useEffect, useState } from 'react';
import { getDataApi } from '../../../../@crema/utility/APIHooks';
import { getDateDisplay } from '../../../../@crema/utility/Utils';
import './index.style.less';

const GiftDetailModal = ({ visible = true, onRedeemClick, onCancel, gift }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState({});

  const getContent = async () => {
    setIsLoading(true);
    const res = await getDataApi(`/gifts/${gift.id}`);
    setIsLoading(false);
    if (res) {
      setContent(res);
    }
  };

  useEffect(() => {
    getContent();
  }, []);

  return (
    <>
      <Modal
        title={
          <div>
            <Title level={2}>{gift.name}</Title>
            <Text>{gift.brand}</Text>
          </div>
        }
        open={visible}
        onCancel={onCancel}
        destroyOnClose
        width={600}
        footer={
          <Row gutter={16}>
            <Col xs={8} style={{ marginBottom: 12 }} offset={4}>
              <Button key='back' type='primary' ghost block onClick={onCancel}>
                DISCARD
              </Button>
            </Col>
            <Col xs={8} style={{ marginBottom: 12 }}>
              <Button
                block
                key='submit'
                type='primary'
                loading={isLoading}
                onClick={onRedeemClick}>
                {'Redeem reward'}
              </Button>
            </Col>
          </Row>
        }>
        <Row>
          <Col md={8}>
            <Avatar src={gift.image} shape='square' size={120} />
            <br />
            <br />
            <Text strong>Valid util</Text>
            <br />
            <Text>{getDateDisplay(gift.expiredAt)}</Text>
            <br />
            <br />
            <Text strong>Total Points</Text>
            <br />
            <Text strong className='text-primary font-20'>
              {gift.point}
            </Text>
          </Col>
          <Col md={16}>
            <Text className='enter-content'>
              <Linkify options={{ target: 'blank', className: 'blue-link' }}>
                {content?.description}
              </Linkify>
            </Text>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default GiftDetailModal;
