import { patchDataApi, postDataApi, putDataApi } from '@crema/utility/APIHooks';
import { useAuthUser } from '@crema/utility/AuthHooks';
import { Button, Modal, Typography } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppConfigs } from '../../../shared/config';
import '../../chat/ChatPage/ChatContent/index.style.less';
import EventRegistrationModal from './EventRegistrationModal';
import './index.style.less';
import { GroupRequestButton } from '../GroupDetailPage/GroupRequestButton';

const EventRegisterButton = ({ event, onRefresh, className, btnClassName }) => {
  const [loading, setLoading] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const { user } = useAuthUser();
  const [registerModalVisible, setRegisterModalVisible] = useState(false);
  const navigate = useNavigate();
  const { confirm } = Modal;

  const isGroupEvent = !!event.groupId;

  const role = event.group?.groupMembers?.find(
    (x) => x.userId === user?.id,
  )?.role;

  const isRegisted = !!event.eventParticipants?.filter(
    (x) => x.userId === user?.id,
  )?.length;

  const isInvited = !!event.eventInvitations?.filter((x) => x.toId === user?.id)
    ?.length;

  const isHost = role === 'Host';
  const isGroupMember = !!role;

  const onRegisterEventClick = async () => {
    if (!user) {
      localStorage.setItem('navigation', `/event/${event.id}`);
      window.location.href = '/signin';
      return;
    }

    setRegisterModalVisible(true);
  };

  const rejectEventInvitation = async () => {
    setRejecting(true);
    const invitation = event.eventInvitations[0];
    await putDataApi(
      `/events/invitations/${invitation.id}/reject`,
      {},
      {
        fetchSuccess: () => {
          onRefresh?.();
        },
      },
    );
    setRejecting(false);
  };

  const onCancelEvent = async () => {
    confirm({
      title: 'Cancel Registration',
      content: (
        <Typography>
          Are you sure you want to cancel registration for the event:{' '}
          <b>{event.title}</b>?
        </Typography>
      ),
      okText: 'CANCEL REGISTRATION',
      cancelText: 'NO, KEEP REGISTRATION',
      cancelButtonProps: { style: { marginBottom: 16 } },
      async onOk() {
        if (event.id) {
          setLoading(true);
          await patchDataApi(
            `${AppConfigs.apiV2Url}/api-svc/v1/events/${event.id}/cancel`,
            {},
            {
              fetchSuccess: () => {
                onRefresh?.();
              },
            },
          );
          setLoading(false);
        }
      },
      onCancel() {},
    });
  };

  const isFull = event.ongoing >= event.capacity;

  if (isGroupEvent && !isGroupMember) {
    return (
      <div className={`${className}`}>
        <GroupRequestButton
          btnClassName='d-100'
          group={event.group}
          onRefresh={onRefresh}
        />
      </div>
    );
  }

  return (
    <div className={`${className}`}>
      {isRegisted && (
        <Button
          type='primary'
          className={`btn-secondary2 ${btnClassName}`}
          onClick={onCancelEvent}>
          REGISTERED
        </Button>
      )}
      {!isRegisted &&
        (isInvited ? (
          <>
            <Button
              disabled={isFull}
              type='primary'
              className={`btn-secondary2 ${btnClassName}`}
              loading={loading}
              onClick={onRegisterEventClick}>
              ACCEPT
            </Button>
            <Button
              disabled={isFull}
              type='link'
              className={`fw-400 ${btnClassName}`}
              loading={rejecting}
              onClick={rejectEventInvitation}>
              Reject invitation
            </Button>
          </>
        ) : (
          <Button
            disabled={isFull}
            type='primary'
            className={`btn-primary2 ${btnClassName}`}
            loading={loading}
            onClick={onRegisterEventClick}>
            REGISTER
          </Button>
        ))}
      {registerModalVisible && (
        <EventRegistrationModal
          event={event}
          onCancel={() => setRegisterModalVisible(false)}
          onCreate={() => {
            setRegisterModalVisible(false);
            onRefresh?.(event);
          }}
        />
      )}
    </div>
  );
};

export default EventRegisterButton;
