import { Button, Col, Image, Modal, Row, Space, Spin, Tabs } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useEffect, useState } from 'react';
import { getDataApi } from '../../../../@crema/utility/APIHooks';
import ObjectTable from './ObjectTable';

const RewardFAQ = ({ onCancel }) => {
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(true);
  const [faq, setFaq] = useState({});

  useEffect(() => {
    const asyncFn = async () => {
      const res = await getDataApi(`/faq`, {});
      setLoading(false);
      setFaq(res);
      setVisible(true);
    };
    asyncFn();
  }, []);

  return (
    <>
      <Modal
        title='FAQ'
        open={visible}
        width={900}
        onCancel={onCancel}
        destroyOnClose
        bodyStyle={{ paddingTop: 0 }}
        closeIcon={
          <i className='wc-icon icon-ic_close' style={{ fontSize: 24 }} />
        }
        footer={
          <Row gutter={16} className='mt-16'>
            <Col
              xs={16}
              style={{ marginBottom: 12, textAlign: 'center' }}
              offset={4}>
              <Space>
                <Button danger type='primary' onClick={() => onCancel()}>
                  GOT IT!
                </Button>
              </Space>
            </Col>
          </Row>
        }>
        <Tabs defaultActiveKey='1' centered>
          <Tabs.TabPane key='1' tab='POINTS'>
            <Spin spinning={loading}>
              <Row gutter={16} className='mt-32 d-100'>
                <Col xs={24} md={24}>
                  <Text className='text-secondary font-16 fw-600'>
                    How to earn points?
                  </Text>
                  <br />
                  <Text className='text-back font-14 lh-24'>
                    Collect points by doing some activities through MentoringSG
                    app.
                  </Text>
                  <br />
                  <br />
                  <Text className='text-secondary font-16 mt-24 fw-600'>
                    Earn points when you do these activities
                  </Text>
                  <br />
                  <br />
                  <Text className='text-black font-14 mt-24 fw-600'>
                    Connection with a Mentee/Mentor
                  </Text>
                  <br />
                  <ObjectTable
                    data={faq?.point?.connection?.map((x) => ({
                      label: x.label,
                      value: `${x.point} points`,
                    }))}
                  />
                  <br />
                  <Text className='text-black font-14 mt-24 fw-600'>
                    Completed Mentorship
                  </Text>
                  <br />
                  <ObjectTable
                    data={faq?.point?.mentorship?.map((x) => ({
                      label: x.label,
                      value: `${x.point} points`,
                    }))}
                  />
                  <br />
                  <Row>
                    <Col xs={24} md={16}>
                      <Text className='text-black font-14 mt-24 fw-600'>
                        First message with a Mentee/Mentor
                      </Text>
                      <br />
                      <Row className='d-space-between'>
                        <Text className='text-black font-14'>
                          For each different connection
                        </Text>
                        <Text className='text-primary'>
                          {faq?.point?.firstMessage?.point} points
                        </Text>
                      </Row>
                      <br />
                      <Text className='text-black font-14 mt-24 fw-600'>
                        First Mentorship session
                      </Text>
                      <br />
                      <Row className='d-space-between'>
                        <Text className='text-black font-14'>
                          For each different mentorship
                        </Text>
                        <Text className='text-primary'>
                          {faq?.point?.firstSession?.point} points
                        </Text>
                      </Row>
                      <br />
                      <Row className='d-space-between'>
                        <Text className='text-black font-14 fw-600'>
                          Asking a question on Voices
                        </Text>
                        <Text className='text-primary'>
                          {faq?.point?.askingQuestionOnVoice?.point} points
                        </Text>
                      </Row>
                      <br />
                      <Row className='d-space-between'>
                        <Text className='text-black font-14 fw-600'>
                          Answering a question on Voices
                        </Text>
                        <Text className='text-primary'>
                          {faq?.point?.answeringQuestionOnVoice?.point} points
                        </Text>
                      </Row>
                      <br />
                      <Row className='d-space-between'>
                        <Text className='text-black font-14 fw-600'>
                          Create a group
                        </Text>
                        <Text className='text-primary'>
                          {faq?.point?.createGroup?.point} points
                        </Text>
                      </Row>
                      <br />
                      <Row className='d-space-between'>
                        <Text className='text-black font-14 fw-600'>
                          Completed Onboarding programme
                        </Text>
                        <Text className='text-primary'>
                          {faq?.point?.completeOnboarding?.point} points
                        </Text>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Spin>
          </Tabs.TabPane>
          <Tabs.TabPane key='2' tab='MEMBERSHIP' style={{ overflowY: 'auto' }}>
            <table className='tb-membership'>
              <tr>
                <th>MEMBER</th>
                <th>BASIC MEMBER</th>
                <th>SILVER MEMBER</th>
                <th>GOLD MEMBER</th>
              </tr>
              <tr>
                <td>
                  <Text className='fw-600'>BADGE</Text>
                </td>
                <td className='text-center'>
                  <Image preview={false} src='/assets/images/b_basic.svg' />
                </td>
                <td className='text-center'>
                  <Image preview={false} src='/assets/images/b_silver.svg' />
                </td>
                <td className='text-center'>
                  <Image preview={false} src='/assets/images/b_gold.svg' />
                </td>
              </tr>
              <tr>
                <td>
                  <Text className='fw-600'>WHAT IS THIS?</Text>
                </td>
                <td>
                  Basic Member is a level of member that will get some benefit
                  from WeConnect.
                </td>
                <td>
                  <Text>
                    Silver Member is a level of member that surpassed{' '}
                    <Text className='text-primary'>50.000</Text> points and will
                    get some benefit from WeConnect.
                  </Text>
                </td>
                <td>
                  <Text>
                    Gold Member is a level of member that surpassed{' '}
                    <Text className='text-primary'>100.000</Text> points and
                    will get some benefit from WeConnect.
                  </Text>
                </td>
              </tr>
              <tr>
                <td>
                  <Text className='fw-600'>BENEFITS</Text>
                </td>
                <td className='tb-membership__top'>
                  <Space>
                    <Image
                      size={64}
                      preview={false}
                      src='/assets/images/g1.svg'
                    />
                    <Text>Redeem Gift</Text>
                  </Space>
                </td>
                <td className='tb-membership__top'>
                  <Space>
                    <Image preview={false} src='/assets/images/g1.svg' />
                    <Text>Redeem Gift</Text>
                  </Space>
                  <br />
                  <br />
                  <Space>
                    <Image preview={false} src='/assets/images/g2.svg' />
                    <Text>Prioritized Connection</Text>
                  </Space>
                </td>
                <td className='tb-membership__top'>
                  <Space>
                    <Image preview={false} src='/assets/images/g1.svg' />
                    <Text>Redeem Gift</Text>
                  </Space>
                  <br />
                  <br />
                  <Space>
                    <Image preview={false} src='/assets/images/g2.svg' />
                    <Text>Prioritized Connection</Text>
                  </Space>
                  <br />
                  <br />
                  <Space>
                    <Image preview={false} src='/assets/images/g3.svg' />
                    <Text>Prioritized Support</Text>
                  </Space>
                </td>
              </tr>
            </table>
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default RewardFAQ;
