import { Badge, Dropdown, List, Menu } from 'antd';
import { useEffect, useState } from 'react';
import EmptyPlaceholder from '../../../pages/search/SearchPage/EmptyPlaceholder';
import { getDataApi } from '../../utility/APIHooks';
import Text from 'antd/lib/typography/Text';
import { useMediaQuery } from 'react-responsive';
import IntlMessages from '../../utility/IntlMessages';
import AppScrollbar from '../AppScrollbar';
import './index.style.less';
import NotificationItem from './NotificationItem';
import { useAuthMethod, useAuthUser } from '../../utility/AuthHooks';

const AppNotifications = () => {
  const isMobileAndTablet = useMediaQuery({ query: '(max-width: 768px)' });
  const { user } = useAuthUser();
  const { getUserProfile } = useAuthMethod();

  const [notifications, setNotifications] = useState([]);

  const getUser = () => {
    if (!user?.name) {
      getUserProfile();
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      getUser();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const getNotifications = async () => {
    const { data: notifications } =
      (await getDataApi('/notifications', {
        take: 30,
        isOnline: true,
      })) || {};
    setNotifications(notifications);
  };

  useEffect(() => {
    getNotifications();
    const interval = setInterval(() => {
      getNotifications();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const data = notifications;
  const menu = (
    <Menu className='notify-header-message'>
      <Menu.Item className='header'>
        <IntlMessages id='common.notifications' />
      </Menu.Item>
      <Menu.Item>
        <AppScrollbar className='notify-scroll-submenu'>
          {!!data?.length && (
            <List
              className='notify-list'
              dataSource={data}
              renderItem={(item) => {
                return <NotificationItem key={item.id} item={item} />;
              }}
            />
          )}
          {!data?.length && (
            <EmptyPlaceholder
              style={{ paddingTop: 0, minHeight: 360 }}
              textStyle={{ marginTop: 0 }}
              image={'/assets/images/notification.png'}
              description={'You have no notification.'}
            />
          )}
        </AppScrollbar>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      className='dropdown dropdown-notification'
      trigger={['click']}>
      <a className='notify-link' onClick={(e) => e.preventDefault()}>
        <Badge
          count={data?.filter((x) => !x.isRead)?.length || 0}
          style={{ minWidth: 12, minHeight: 12 }}
          color={
            data?.filter((x) => !x.isRead)?.length > 0 ? '#c00000' : undefined
          }
          dot={false}
          offset={[-16, 3]}>
          <span className='d-flex' style={{ flexDirection: 'column' }}>
            <span
              className='wc-icon icon-ic_noti'
              style={{
                fontSize: isMobileAndTablet ? 20 : 24,
                color: '#4D4D4D',
              }}
            />
            {!isMobileAndTablet && (
              <Text
                className={`menu-notification ${
                  location.pathname.includes('notification')
                    ? 'text-primary'
                    : 'text-menu'
                }`}
                style={{ fontSize: 10, marginTop: 6 }}>
                NOTIFICATION
              </Text>
            )}
          </span>
        </Badge>
      </a>
    </Dropdown>
  );
};

export default AppNotifications;
