import jwtAxios from '@crema/services/auth/jwt-auth/jwt-api';
import { postDataApi } from '@crema/utility/APIHooks';
import { useAuthUser } from '@crema/utility/AuthHooks';
import { Button, Col, Form, Input, message, Modal, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import { AppConfigs } from '../../../shared/config';
import PhoneInput from '../../profile/UserProfile/PersonalInfo/PhoneInput';
import './index.style.less';
import { isValidPhoneNumber } from 'libphonenumber-js';

const EventRegistrationModal = ({
  invitationId = undefined,
  visible = true,
  onCreate,
  onCancel,
  event,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuthUser();
  const [content, setContent] = useState({});

  const getContent = async () => {
    setIsLoading(true);
    await jwtAxios
      .get('content')
      .then((res) => {
        setContent(res.data);
      })
      .catch(() => {});
    setIsLoading(false);
  };
  useEffect(() => {
    getContent();
  }, []);

  const onSubmitEvent = (values) => {
    if (values.phone) {
      const isValid = isValidPhoneNumber(values.phone);
      if (!isValid) {
        form?.setFields([
          {
            name: 'phone',
            errors: ['Invalid contact number'],
          },
        ]);
        return;
      }
    }

    setIsLoading(true);
    postDataApi(
      `/events/register`,
      {
        invitationId,
        ...values,
        eventId: event.id,
      },
      {
        fetchSuccess: () => {
          message.success(`You have successfully registered the event!`, 4);
          onCancel(false);
          setIsLoading(false);
          onCreate?.();
        },
        fetchError: () => {
          setIsLoading(false);
        },
      },
    );
  };

  const showConfirm = () => {
    onCancel?.();
  };

  return (
    <>
      <Modal
        title={`Register to "${event.title}"`}
        open={visible}
        onCancel={onCancel}
        closable={true}
        destroyOnClose
        width={960}
        footer={
          <Row gutter={16}>
            <Col
              xs={24}
              md={{
                span: 6,
                offset: 6,
              }}
              style={{ marginBottom: 12 }}>
              <Button
                key='back'
                type='primary'
                ghost
                block
                onClick={showConfirm}>
                CANCEL
              </Button>
            </Col>
            <Col
              xs={24}
              md={{
                span: 6,
              }}
              style={{ marginBottom: 12 }}>
              <Button
                block
                key='submit'
                type='primary'
                loading={isLoading}
                onClick={() => {
                  form
                    .validateFields()
                    .then((values) => {
                      onSubmitEvent(values);
                    })
                    .catch(() => {});
                }}>
                CONFIRM REGISTRATION
              </Button>
            </Col>
          </Row>
        }>
        <Form
          form={form}
          name='event-register-form'
          initialValues={{
            firstName: user?.profile?.firstName,
            lastName: user?.profile?.lastName,
            industryId: user?.profile?.industryId,
            email: user?.email,
            phone: user?.profile?.contactInfo?.phone,
            organisation: user?.profile?.company,
          }}
          autoComplete='off'
          layout='vertical'>
          <Row gutter={16} className='event-create-form'>
            <Col md={12} xs={24}>
              <Form.Item
                name='firstName'
                label='First Name'
                requiredMark={'optional'}
                rules={[{ required: true, message: 'Enter First Name' }]}>
                <Input max={255} placeholder='Firt name' />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                name='lastName'
                label='Last Name'
                requiredMark={'optional'}
                rules={[{ required: true, message: 'Enter Last Name' }]}>
                <Input max={255} placeholder='Last name' />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                name='email'
                label='Email'
                requiredMark={'optional'}
                rules={[{ required: true, message: 'Enter Email' }]}>
                <Input max={255} placeholder='Email' type='email' />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <PhoneInput
                name={'phone'}
                label={'Contact Number'}
                initialValue={user.profile?.contactInfo?.phone}
                form={form}
                rules={[
                  {
                    required: true,
                    message: 'Enter Contact Number!',
                  },
                ]}
              />
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                name='organisation'
                label={'Organisation / School'}
                requiredMark={'optional'}
                rules={[
                  {
                    required: true,
                    message: 'Enter your Organization / School',
                  },
                ]}>
                <Input
                  type='text'
                  maxLength={255}
                  placeholder='Your organization (Or School if student)'
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                name='industryId'
                label={'Industry'}
                requiredMark={'optional'}
                rules={[
                  {
                    required: true,
                    message: 'Select Industry',
                  },
                ]}>
                <Select
                  placeholder='Select'
                  showSearch
                  allowClear
                  optionFilterProp='name'
                  filterOption={(input, option) => {
                    return option.name
                      ?.toLowerCase()
                      ?.includes(input?.toLowerCase());
                  }}>
                  {content.industries?.map((industry) => {
                    return (
                      <Select.Option
                        key={industry.id}
                        value={industry.id}
                        name={industry.name}>
                        {industry.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default EventRegistrationModal;
