import React from 'react';
import { RoutePermittedRole } from '../../shared/constants/AppEnums';

const ChatPage = React.lazy(() => import('./ChatPage'));

export const chatPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/chat',
    element: <ChatPage />,
  },
  {
    permittedRole: RoutePermittedRole.user,
    path: '/chat/:id',
    element: <ChatPage />,
  },
];
