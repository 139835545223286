import {
  Button,
  Card,
  Col,
  message,
  Modal,
  Popover,
  Row,
  Space,
  Tag,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteDataApi, postDataApi } from '@crema/utility/APIHooks';
import { useLayoutActionsContext } from '@crema/utility/AppContextProvider/LayoutContextProvider';
import Avatar from '../../../components/Avatar';
import ScheduleModal from '../../calendar/CalendarPage/ScheduleModal';
import './index.style.less';

export const TagLevel = ({ user, className, ...props }) => {
  const isMentor = user?.accountType === 'Mentor';
  return (
    <Tag
      className={`${isMentor ? 'user-mentor' : 'user-mentees'} ${className}`}
      {...props}>
      {isMentor ? `Level ${user.profile?.level || 0} Mentor` : 'Mentee'}
    </Tag>
  );
};

export const TagStatus = ({ status, type, className, ...props }) => {
  return (
    <Tag className={`tag-${type} ${className}`} {...props}>
      {status}
    </Tag>
  );
};

const UserCard = ({
  user,
  className,
  visitProfile = true,
  hasMessage = false,
  hasMore = false,
  isMentor = false,
  isMentee = false,
  hasSchedule = false,
  connectionId,
  connection,
  group,
  onDeleteConnectionFinished,
}) => {
  const { setModalUserId } = useLayoutActionsContext();

  const { confirm } = Modal;

  const showConfirmDelete = () => {
    confirm({
      title: 'REMOVE CONNECTION',
      content: `Are you sure you want to remove ${user.name}?`,
      okText: 'REMOVE',
      cancelText: 'DISCARD',
      async onOk() {
        if (connectionId) {
          await deleteDataApi('/connections/' + connectionId, {
            fetchSuccess: () => {
              message.success(`${user.name} has been removed.`);
            },
          });
          onDeleteConnectionFinished?.();
        }
      },
      onCancel() {},
    });
  };

  const onVisitProfile = () => {
    setModalUserId(user.id);
  };

  const [visibleSchedule, setVisibleSchedule] = useState(false);

  return (
    <Card className={`user-card ${className}`}>
      {hasMore && (
        <Popover
          content={
            <div className='row'>
              <i className='wc-icon icon-ic_trash' />
              <Button
                className='btn-inactive'
                type='link'
                style={{ padding: 0 }}
                onClick={showConfirmDelete}>
                REMOVE CONNECTION
              </Button>
            </div>
          }>
          <Button type='link' className='btn-inactive user-card__btn-more'>
            <i className='wc-icon icon-ic_more-horizontal' />
          </Button>
        </Popover>
      )}
      <Avatar
        user={user}
        size={80}
        className='user-card-avatar'
        style={{ marginTop: 16, marginRight: 16 }}
      />
      <TagLevel user={user} />
      <Text className='user-name' ellipsis>
        {user.name}
      </Text>
      <Text className='user-workplace' ellipsis>
        {user.profile?.contactInfo?.currentDesignation}
      </Text>
      {visitProfile && (
        <Button onClick={onVisitProfile} type='primary'>
          VISIT PROFILE
        </Button>
      )}
      {hasSchedule && (
        <Row className='d-100 mt-16'>
          <Col xs={12} className='user-card__session user-card__session-left'>
            <Text className='user-card__session__title'>Last Session</Text>
            <Text className='user-card__session__value mt-4'>
              {connection.lastSessionAt
                ? moment(connection.lastSessionAt).format('MM/DD/YYYY')
                : '--'}
            </Text>
          </Col>
          <Col xs={12} className='user-card__session'>
            <Text className='user-card__session__title'>Next Session</Text>
            <Text className='user-card__session__value mt-4 user-card__session__value-primary'>
              {connection.nextSessionAt
                ? moment(connection.nextSessionAt).format('MM/DD/YYYY')
                : '--'}
            </Text>
          </Col>
        </Row>
      )}
      <Space className='row'>
        {hasMessage &&
          (isMentee || isMentor ? (
            <MessageButton
              hasLabel={false}
              connection={connection}
              group={group}
            />
          ) : (
            <MessageButton hasLabel connection={connection} group={group} />
          ))}
        {hasSchedule && (
          <Button type='primary' onClick={() => setVisibleSchedule(true)}>
            <i className='wc-icon icon-ic_calendar' />
            schedule session
          </Button>
        )}
      </Space>
      {visibleSchedule && (
        <ScheduleModal
          mentees={[connection]}
          initConnection={connection}
          visible={visibleSchedule}
          onCancel={() => {
            setVisibleSchedule(false);
          }}
        />
      )}
    </Card>
  );
};

export default UserCard;

export const MessageButton = ({
  connection,
  group,
  className,
  onClick,
  link = false,
  hasLabel = true,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const getChatRoom = async () => {
    setLoading(true);
    const data = await postDataApi(`/chats`, {
      connectionId: connection?.id,
      groupId: group?.id,
    });
    setLoading(false);
    return data;
  };

  const onButtonClick = async () => {
    onClick?.();

    if (connection?.chatRoomId) {
      navigate(`/chat/${connection.chatRoomId}?type=Connection`);
      return;
    }

    if (group?.chatRoomId) {
      await getChatRoom();
      navigate(
        `/network/community/${group.id}?chatRoomId=${group?.chatRoomId}&p=bottom&type=HOME`,
      );
      return;
    }

    const chatRoom = await getChatRoom();
    if (chatRoom.data) {
      if (group) {
        navigate(
          `/network/community/${group.id}?chatRoomId=${chatRoom.data.id}&p=bottom&type=HOME`,
        );
      } else {
        navigate(`/chat/${chatRoom.data.id}?type=Connection`);
      }
    }
  };

  return (
    <Button
      className={`user-card__btn-message ${className}`}
      onClick={onButtonClick}
      danger
      loading={loading}
      type={link ? 'link' : 'ghost'}>
      <i className='wc-icon icon-ic_message' />
      {hasLabel && 'MESSAGE'}
    </Button>
  );
};
