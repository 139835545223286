import { Card } from 'antd';
import Text from 'antd/lib/typography/Text';
import { getDateDisplay } from '../../../../@crema/utility/Utils';
import Avatar from '../../../../components/Avatar';
import { TagStatus } from '../../../search/SearchPage/InfoCard';
import './index.style.less';

const RedeemItem = ({ data, className, onImageClick }) => {
  const statusType = {
    Pending: 'pending',
    Approved: 'info',
    Rejected: 'danger',
  };

  return (
    <Card className={`gift-card ${className}`}>
      <Avatar
        src={data.gift?.image}
        size={80}
        title={data.name}
        onClick={onImageClick}
        className='user-card-avatar'
        style={{ marginTop: 16, marginRight: 16 }}
      />
      <TagStatus type={statusType[data.status]} status={data.status} />
      <br />
      <Text
        className='user-name btn-pointer font-16'
        ellipsis
        onClick={onImageClick}>
        {data.name}
      </Text>
      <br />
      {!data.code ? (
        <>
          <Text className='text-gray font-14 mb-8'>Redeemed on</Text>
          <Text strong className='text-black font-14 mb-4'>
            {getDateDisplay(data.createdAt)}
          </Text>
          <br />
        </>
      ) : (
        <>
          <Text className='text-gray font-14 mb-8'>Redeemed code</Text>
          <Text strong className='text-secondary font-32'>
            {data.code}
          </Text>
        </>
      )}
      <Text
        strong
        className={`text-primary font-16 mb-16 mt-16 ${
          !data.code && 'text-gray'
        }`}>
        -{data.amount} POINTS
      </Text>
    </Card>
  );
};

export default RedeemItem;
