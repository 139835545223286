import { postDataApi } from '@crema/utility/APIHooks';
import { Button, Card, Row } from 'antd';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppConfigs } from '../../../shared/config';
import './index.style.less';
import EventRegisterButton from '../EventDetailPage/EventRegisterButton';

const EventCard = ({ event, onRefresh }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onRegisterEventClick = async () => {
    setLoading(true);
    await postDataApi(`${AppConfigs.apiV2Url}/events/requests`, {
      eventId: event.id,
    });
    setLoading(false);
    onRefresh?.();
  };

  const isRegistered = !!event.eventParticipants?.length;

  return (
    <Card className='event-card'>
      <div
        className='event-img'
        onClick={() => navigate(`/event/${event.id}`)}
        style={{
          backgroundImage: `url(${event.featureImage})`,
        }}
      />
      <div className='event-body'>
        <Row className='d-space-between mb-8'>
          <span style={{ color: '#333333' }}>
            <span
              className='wc-icon icon-ic_calendar'
              style={{ color: '#808080' }}
            />
            {moment.utc(event.date).format('MMM DD, yyyy')}
          </span>
        </Row>
        <Row className='d-space-between mb-8'>
          <span style={{ color: '#333333' }}>
            <span
              className='wc-icon icon-ic_clock'
              style={{ color: '#808080' }}
            />
            {`${moment(event.startTime).format('hh:mm A')} ${event.endTime ? `- ${moment(event.endTime).format('hh:mm A')}` : ''}`}
          </span>
        </Row>
        <Link to={`/event/${event.id}`} className='event-title-link ml-8'>
          <Text className='event-title btn-link'>{event.title}</Text>
        </Link>
        <span className='event-desc pt-0 pl-8'>{event.description}</span>
        <Row className='event-location d-space-between mb-8'>
          <span style={{ color: '#333333' }}>
            <span
              className='msg-icon ico2-icc_location'
              style={{ color: '#808080' }}
            />
            {event.location || '--'}
          </span>
        </Row>
        <Row className='d-space-between mb-8'>
          <span style={{ color: '#333333' }}>
            <span
              className='msg-icon ico2-icc_icon'
              style={{ color: '#808080' }}
            />
            {event.ongoing || '0'} people going
          </span>
          <EventRegisterButton
            className='mt-8'
            event={event}
            onRefresh={onRefresh}
          />
        </Row>
      </div>
    </Card>
  );
};

export default EventCard;
