import React from 'react';
import { RoutePermittedRole } from '../../shared/constants/AppEnums';

const NetworkPage = React.lazy(() => import('./NetworkPage'));
const CommunityPage = React.lazy(() => import('./NetworkPage/CommunityPage'));
const EventsPage = React.lazy(() => import('./NetworkPage/EventsPage'));
const GroupDetailPage = React.lazy(() => import('./GroupDetailPage'));
const EventDetailPage = React.lazy(() => import('./EventDetailPage'));

export const connectionRootPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/network',
    element: <NetworkPage />,
  },
];

export const communityRootPagesConfigs = [
  {
    path: '/community',
    element: <CommunityPage />,
  },
];

export const connectionChildPagesConfigs = [
  {
    path: '/network/community/:id',
    element: <GroupDetailPage />,
  },
];

export const eventRootPagesConfigs = [
  {
    path: '/event',
    element: <EventsPage />,
  },
];

export const eventChildPagesConfigs = [
  {
    path: '/event/:id',
    element: <EventDetailPage />,
  },
];
