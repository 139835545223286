import React from 'react';
import { RoutePermittedRole } from '../../shared/constants/AppEnums';

const CalendarPage = React.lazy(() => import('./CalendarPage'));

export const calendarPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/calendar',
    element: <CalendarPage />,
  },
];
