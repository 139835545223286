import { AppSpin } from './../AppSpin';
import './index.style.less';

const AppLoader = () => {
  return (
    <div className='app-loader'>
      <AppSpin />
    </div>
  );
};

export default AppLoader;
