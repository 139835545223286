import { Form, Input } from 'antd';
import CountryPhoneInput from 'antd-country-phone-input';
import { useEffect, useState } from 'react';
import './index.style.less';
import _ from 'lodash';
import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js';

const PhoneInput = ({
  form,
  initialValue = '',
  name = ['contactInfo', 'phone'],
  label = 'Contact Number',
  rules = [
    {
      required: true,
      message: 'Please input your Contact Number!',
    },
  ],
  placeholder = 'Enter Contact Number',
  onChange,
  requiredMark = 'optional',
}) => {
  const [phone, setPhone] = useState({ short: 'SG' });

  useEffect(() => {
    if (!initialValue) {
      setPhone({ short: 'SG' });
      return;
    }

    const isSGPhone =
      _.startsWith(initialValue, '+65') || initialValue.length === 8;

    if (isSGPhone) {
      setPhone({
        short: 'SG',
        code: 65,
        phone: initialValue.replace('+65', ''),
      });

      const newValue = `+65${initialValue.replace('+65', '')}`;
      form?.setFieldValue(name, newValue);
      return;
    }

    const isValid = isValidPhoneNumber(initialValue);

    if (isValid) {
      const parsedPhone = parsePhoneNumber(initialValue);

      setPhone({
        short: parsedPhone.country,
        code: parsedPhone.countryCallingCode,
        phone: parsedPhone.nationalNumber,
      });

      const newValue = `+${parsedPhone.countryCallingCode}${parsedPhone.nationalNumber}`;
      form?.setFieldValue(name, newValue);
    } else if (
      initialValue.length >= 10 &&
      !_.startsWith(initialValue, '+') &&
      isValidPhoneNumber(`+${initialValue}`)
    ) {
      const parsedPhone = parsePhoneNumber(`+${initialValue}`);
      setPhone({
        short: parsedPhone.country,
        code: parsedPhone.countryCallingCode,
        phone: parsedPhone.nationalNumber,
      });
      const newValue = `+${parsedPhone.countryCallingCode}${parsedPhone.nationalNumber}`;
      form?.setFieldValue(name, newValue);
      onChange?.(newValue);
    }
  }, [initialValue]);

  return (
    <Form.Item
      name={name}
      label={label}
      requiredMark={requiredMark}
      rules={rules}>
      <Input placeholder={placeholder} style={{ display: 'none' }} />
      <CountryPhoneInput
        value={phone}
        inline
        placeholder={placeholder}
        maxLength={12}
        onBlur={() => {
          const val = form?.getFieldValue(name);
          if (val) {
            const isValid = parsePhoneNumber(val).isValid();
            if (!isValid) {
              form?.setFields([
                {
                  name,
                  errors: ['Invalid phone number'],
                },
              ]);
            }
          }
        }}
        onChange={(v) => {
          let newValue = `+${v.code}${v.phone}`;
          if (!v.phone) {
            newValue = '';
          }
          form?.setFieldValue(name, newValue);
          onChange?.(newValue);
          if (newValue) {
            form?.setFields([
              {
                name,
                errors: [],
              },
            ]);
          }

          setPhone(v);
        }}
      />
    </Form.Item>
  );
};

export default PhoneInput;
