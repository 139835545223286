import { Card, Skeleton } from 'antd';

export const EventCardLoading = () => {
  return (
    <Card style={{ height: 490 }}>
      <Skeleton.Image
        active
        className='d-100'
        style={{ height: 156, width: '100%' }}
      />
      <Skeleton active className='mt-32' />
      <Skeleton.Button
        active
        style={{
          position: 'absolute',
          bottom: 24,
          width: 120,
          right: 24,
        }}
      />
    </Card>
  );
};
