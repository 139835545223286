import { postDataApi } from '@crema/utility/APIHooks';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Typography,
  message,
} from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const SupportPage = () => {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(null);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('submitContact');
    setToken(token);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const onFinish = async (values) => {
    await handleReCaptchaVerify();

    if (!token) {
      return;
    }

    setIsLoading(true);
    postDataApi(
      'api-svc/v1/contacts',
      { ...values, reCAPTCHAToken: token },
      {
        fetchSuccess: () => {
          setIsLoading(false);
          message.success(
            'Your message has been sent successfully, we will be in touch soon.',
          );
          form.resetFields();
        },
        fetchError: () => {
          message.error(
            'There is unexpected error happened, please double check your message and retry.',
          );
          setIsLoading(false);
        },
      },
      () => {
        setIsLoading(false);
      },
    );
  };

  return (
    <Form form={form} initialValues={{}} onFinish={onFinish} layout='vertical'>
      <h3 className='text-center'>
        <Typography style={{ fontSize: 32, fontWeight: 'bold' }}>
          Reach out to us
        </Typography>
      </h3>
      <p className='text-center'>
        Feeling stuck? Feel free to drop us a message if you have any questions.
        Our team will respond to you shortly.
      </p>
      <br />
      <br />
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name='name'
            label='Name'
            requiredMark={'optional'}
            rules={[
              { required: true, max: 255 },
              {
                message: 'Name is not valid.',
                pattern: new RegExp(`^[a-zA-Z ]*$`),
              },
            ]}>
            <Input placeholder='Enter name' />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name='phone'
            label='Phone'
            requiredMark={'optional'}
            rules={[
              { required: true, max: 20 },
              {
                message: 'Phone is not valid.',
                pattern: new RegExp('^[0-9-+]+$'),
              },
            ]}>
            <Input placeholder='Enter phone' />
          </Form.Item>
        </Col>

        <Col xs={24} md={24}>
          <Form.Item
            name='email'
            label='Email'
            requiredMark={'optional'}
            rules={[{ required: true, max: 255 }, { type: 'email' }]}>
            <Input placeholder='Enter email' />
          </Form.Item>
        </Col>

        <Col xs={24} md={24}>
          <Form.Item
            name='subject'
            label='Subject'
            requiredMark={'optional'}
            rules={[{ required: true }]}>
            <Select placeholder='Select'>
              <Select.Option value='Mentee related Enquires'>
                Mentee related Enquires
              </Select.Option>

              <Select.Option value='Mentor related Enquires'>
                Mentor related Enquires
              </Select.Option>

              <Select.Option value='Corporate related Enquires'>
                Corporate related Enquires
              </Select.Option>

              <Select.Option value='Technical support'>
                Technical support{' '}
              </Select.Option>
              <Select.Option value='Others'>Others </Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} md={24}>
          <Form.Item
            name='content'
            label='Message'
            requiredMark={'optional'}
            rules={[{ required: true, max: 5000 }]}>
            <Input.TextArea rows={5} placeholder='Enter message' />
          </Form.Item>
        </Col>
        <Col xs={24} md={24}></Col>
        <Col xs={24} md={24}>
          <Form.Item shouldUpdate className='user-profile-group-btn'>
            <Row gutter={16}>
              <Col flex={10}>
                <Button
                  type='primary'
                  htmlType='submit'
                  block
                  disabled={!token}
                  loading={isLoading}>
                  SUBMIT
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default SupportPage;
