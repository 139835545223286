import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSidebarContext } from '../../../../utility/AppContextProvider/SidebarContextProvider';
import './index.style.less';

const AppLogo = ({ hasSidebarColor, user }) => {
  const { sidebarColorSet } = useSidebarContext();
  return (
    <div className={`app-logo ${!user && 'app-logo-block'}`}>
      <Link to='/'>
        {hasSidebarColor && sidebarColorSet.mode === 'dark' ? (
          <img
            src='/assets/images/logo-white-with-name.png'
            alt='mentoringsg-logo'
          />
        ) : (
          <img src='/assets/images/logo-with-name.png' alt='mentoringsg-logo' />
        )}
      </Link>
    </div>
  );
};

export default AppLogo;

AppLogo.propTypes = {
  hasSidebarColor: PropTypes.bool,
};
