import { Card, Skeleton } from 'antd';
import './index.style.less';

export const GroupCardLoading = () => {
  return (
    <Card style={{ height: 448 }}>
      <Skeleton.Image
        active
        className='d-100'
        style={{ height: 156, width: '100%' }}
      />
      <Skeleton active className='mt-32' />
    </Card>
  );
};
