import { postDataApi } from '@crema/utility/APIHooks';
import { useAuthUser } from '@crema/utility/AuthHooks';
import { Button } from 'antd';
import { useState } from 'react';
import '../../chat/ChatPage/ChatContent/index.style.less';
import './index.style.less';

export const GroupRequestButton = ({
  group,
  onRefresh,
  style1 = true,
  btnClassName,
}) => {
  const { user } = useAuthUser();
  const [loading, setLoading] = useState(false);

  const onJoinGroupClick = async () => {
    if (!user) {
      localStorage.setItem('navigation', `/network/community/${group.id}`);
      window.location.href = '/signin';
      return;
    }

    setLoading(true);
    const res = await postDataApi('/groups/requests', {
      groupId: group.id,
    });
    setLoading(false);
    onRefresh?.(res?.data);
  };

  const isJoined = group.groupMembers?.some((x) => x.userId === user?.id);
  const role = group.groupMembers?.find((x) => x.userId === user?.id)?.role;
  const isRequested = !!group.requestGroups?.some(
    (x) => x.fromId === user?.id && x.status === 'Pending',
  );
  const isHost = role === 'Host';

  if (!group?.id) {
    return null;
  }

  return (
    <>
      {isRequested && style1 && (
        <Button type='primary' danger className={`${btnClassName}`}>
          <i className='wc-icon icon-ic_check'></i>
          REQUESTING
        </Button>
      )}
      {isRequested && !style1 && (
        <Button type='link' danger className={`btn-black ${btnClassName}`}>
          <i className='wc-icon icon-ic_check'></i>
          REQUESTING
        </Button>
      )}
      {!!group.id && !isJoined && !isRequested && (
        <Button
          loading={loading}
          onClick={onJoinGroupClick}
          className={btnClassName}
          type='primary'>
          JOIN COMMUNITY
        </Button>
      )}
    </>
  );
};
