import { Space } from 'antd';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import UserCard from '../../../search/SearchPage/InfoCard';
import './index.style.less';
import { UserCardLoading } from './UserCardLoading';

const settings = {
  dots: true,
  infinite: true,
  speed: 2000,
  slidesToShow: 4,
  slidesToScroll: 4,
  arrows: true,
  autoplaySpeed: 5000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        dots: true,
        arrows: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: true,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        arrows: true,
        slidesToScroll: 1,
      },
    },
  ],
};

const FeaturedMentors = (props) => {
  const { listMentors, loading } = props;

  return (
    <Slider
      className='slider-card'
      {...settings}
      autoplay={true}
      arrows={false}>
      {!listMentors.length &&
        loading &&
        [1, 2, 3, 4].map((x) => (
          <Space key={x.toString()}>
            <UserCardLoading />
          </Space>
        ))}

      {listMentors.map((user) => (
        <UserCard key={user.id} isMentor={true} user={user} />
      ))}
    </Slider>
  );
};

export default FeaturedMentors;

FeaturedMentors.defaultProps = {
  listMentors: [],
};

FeaturedMentors.propTypes = {
  listMentors: PropTypes.array,
};
