import { Card, Skeleton } from 'antd';
import './index.style.less';

export const UserCardLoading = () => {
  return (
    <Card className='text-center' style={{ height: 310 }}>
      <Skeleton.Avatar active size={82} className='mt-16' />
      <Skeleton active className='mt-32' />
    </Card>
  );
};
