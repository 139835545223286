import React from 'react';
import { RoutePermittedRole } from '../../shared/constants/AppEnums';

const SupportPage = React.lazy(() => import('./SupportPage'));

export const supportPagesConfigs = [
  {
    permittedRole: RoutePermittedRole.user,
    path: '/support',
    element: <SupportPage />,
  },
];
