import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Skeleton,
  Space,
  Spin,
  Tooltip,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import { getDataApi, postDataApi } from '../../@crema/utility/APIHooks';
import { useLayoutActionsContext } from '../../@crema/utility/AppContextProvider/LayoutContextProvider';
import { useAuthUser } from '../../@crema/utility/AuthHooks';
import Avatar from '../../components/Avatar';
import { MessageButton, TagLevel } from '../search/SearchPage/InfoCard';
import { AppConfigs } from './../../shared/config';
import ShareModal from './../voice/VoicePage/ShareModal';
import './user-modal.less';
import { sendFile } from './UserProfile/PersonalInfo';

const UserModal = ({ userId }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const { user: authUser } = useAuthUser();
  const { setModalUserId } = useLayoutActionsContext();
  const [sentRequests, setSentRequests] = useState([]);

  useEffect(() => {
    const asyncFn = async () => {
      if (userId) {
        if (!authUser) {
          const userRes = await getDataApi(`/users/v2/${userId}`);
          setUser(userRes);
          setLoading(false);
          return;
        }
        const userRes = await getDataApi(`/users/${userId}`);
        setUser(userRes);

        const { data: requests } = await getDataApi(
          `/connections/sent-requests`,
          {
            toId: userId,
          },
        );
        setLoading(false);
        setSentRequests(requests);
      }
    };
    asyncFn();
  }, [userId]);

  const [requestNotes, setRequestNotes] = useState('');

  const { confirm } = Modal;

  const [form] = useForm();
  const [fromReport] = useForm();

  const [typeRequest, setTypeRequest] = useState('Mentor');
  const [reason, setReason] = useState('');

  const onTypeChange = (e) => {
    setTypeRequest(e.target.value);
  };

  const friendConnection = user.connections?.find(
    (x) => x.type === 'Connection',
  );

  const mentorMenteeConnection = user.connections?.find(
    (x) => x.type === 'Mentor',
  );

  const hasPendingRequest = sentRequests.find((x) => x.status === 'Pending');
  const isInMentorMenteeRelationship = sentRequests.find(
    (x) => x.status === 'Accepted' && ['Mentor', 'Mentee'].includes(x.type),
  );

  const showConfirmRequest = () => {
    if (authUser?.id === user.id) {
      message.error('You can not send request to yourself.');
      return;
    }

    setTypeRequest(user.accountType);
    confirm({
      title: 'SEND A REQUEST MESSAGE',
      width: '600px',
      content: (
        <div>
          <Form
            form={form}
            initialValues={{ requestNotes, typeRequest: user.accountType }}>
            {!!friendConnection && (
              <div>
                <Text>Add {user.name} as:</Text>
                <Form.Item name='typeRequest'>
                  <Radio.Group value={typeRequest} onChange={onTypeChange}>
                    <Radio disabled={true} value={'Mentor'}>
                      Mentor
                    </Radio>
                    <Radio disabled={true} value={'Mentee'}>
                      Mentee
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
            )}
            <Form.Item name='requestNotes'>
              <Input.TextArea rows={10} maxLength={256} />
            </Form.Item>
          </Form>
        </div>
      ),
      okText: 'SEND REQUEST',
      cancelText: 'DISCARD',
      onOk() {
        const { requestNotes } = form.getFieldsValue('requestNotes');
        const { typeRequest } = form.getFieldsValue('typeRequest');
        setRequestNotes(requestNotes);
        submitRequestConnection(requestNotes, typeRequest);
      },
      onCancel() {
        confirm({
          title: 'DISCARD CONNECTION REQUEST',
          content: `Oops! You have not sent your request. 
          Are you sure you want to leave?`,
          okText: 'CONFIRM',
          cancelText: 'DISCARD',
          onOk() {},
          onCancel() {
            showConfirmRequest();
          },
        });
        return false;
      },
    });
  };

  const [reportModalVisible, setReportModalVisible] = useState(false);
  const showReportModal = () => {
    setReportModalVisible(true);
  };

  const sendRequestConnection = async () => {
    showConfirmRequest();
  };

  const sendRequestConnectionWithoutUser = () => {
    localStorage.setItem('navigation', `/home?profile=${user.id}`);
    window.location.href = '/signin';
  };

  const submitRequestConnection = async (description, typeRequest) => {
    setLoading(true);
    const res = await postDataApi(
      `/connections/requests`,
      {
        toId: userId,
        type: friendConnection ? typeRequest : 'Connection',
        description: description,
      },
      {
        fetchSuccess: () => {
          message.success(
            `Your request has been sent! Just wait for ${user.name} to accept!`,
          );
        },
      },
    );
    setLoading(false);
    if (res) {
      setModalUserId(null);
    }
  };

  const onDeleteRequest = () => {};

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: async (acceptedFiles) => {
      const { data } = await jwtAxios.get('/file/prepare-upload', {
        params: {
          contentType: acceptedFiles[0].type,
          fileName: acceptedFiles[0].name,
          type: 'avatar',
        },
      });

      const url = await sendFile(data.url, acceptedFiles[0]);
      setFiles([...files, url]);
    },
  });

  const onFinishReport = async (values) => {
    setLoading(true);

    const request = {
      reportedUserId: userId,
      reason: values.reason !== 'Others:' ? values.reason : values.otherReason,
      status: 'Pending',
      images: files.join(','),
    };
    await postDataApi(`/users/${userId}/report`, request);
    setLoading(false);
    setReportModalVisible(false);
    message.success(`${user.name} has been reported!`);
  };

  const isValidRequest =
    authUser &&
    ((authUser.profile.level === -1 && user.profile?.level === 2) ||
      (authUser.profile.level >= 0 &&
        authUser.profile.level < user.profile?.level) ||
      (!friendConnection && !mentorMenteeConnection));

  const onShare = () => {};

  return (
    <>
      <Modal
        title=''
        open={true}
        width={700}
        onCancel={() => setModalUserId(null)}
        destroyOnClose
        closeIcon={
          <i className='wc-icon icon-ic_close' style={{ fontSize: 24 }} />
        }
        footer={
          <Row gutter={16} className='mt-16'>
            <Col
              md={{ span: 16, offset: 4 }}
              xs={24}
              style={{ marginBottom: 12, textAlign: 'center' }}>
              <Space>
                {hasPendingRequest ? (
                  <Button danger type='ghost' onClick={onDeleteRequest}>
                    <i className='wc-icon icon-ic_check' />
                    REQUESTED
                  </Button>
                ) : (
                  authUser &&
                  !loading &&
                  !isInMentorMenteeRelationship && (
                    <Tooltip
                      title={
                        isValidRequest
                          ? ''
                          : `You can only send mentorship request to Level ${
                              authUser?.profile?.level === 0
                                ? '1, Level 2'
                                : '2'
                            } mentor(s)`
                      }>
                      <Button
                        block
                        type='primary'
                        loading={loading}
                        disabled={!isValidRequest}
                        onClick={() => sendRequestConnection()}>
                        {!friendConnection && !mentorMenteeConnection
                          ? 'REQUEST FOR CONNECTION'
                          : !mentorMenteeConnection
                            ? 'REQUEST MENTORSHIP'
                            : 'REQUEST MENTORSHIP'}
                      </Button>
                    </Tooltip>
                  )
                )}
                {!authUser && (
                  <Button
                    block
                    type='primary'
                    onClick={sendRequestConnectionWithoutUser}>
                    REQUEST FOR CONNECTION
                  </Button>
                )}
                {!!(friendConnection || mentorMenteeConnection) && (
                  <MessageButton
                    hasLabel
                    onClick={() => setModalUserId(null)}
                    connection={friendConnection || mentorMenteeConnection}
                    group={undefined}
                  />
                )}
              </Space>
            </Col>
          </Row>
        }>
        {!!user?.id && (
          <Space style={{ position: 'absolute', top: 8, left: 0 }}>
            <Button
              className='btn-inactive'
              style={{ width: '60px' }}
              type='link'
              onClick={() => {
                showReportModal();
              }}>
              <i className='wc-icon icon-ic_report' style={{ fontSize: 24 }} />
            </Button>
            <ShareModal
              buttonType='link'
              loading={false}
              trigger='click'
              style={{ color: '#777', paddingLeft: 0 }}
              title={`Connect and learn more about our ${
                user.profile?.level >= 0 ? 'mentor' : 'mentee'
              }, ${user.name}`}
              description={'Mentoring SG Connect - A Spark is All it Takes'}
              url={`${AppConfigs.shareUrl}/u/${user.username}`}
              onClick={onShare}>
              <i className='wc-icon icon-ic_link' style={{ fontSize: 24 }} />
              SHARE
            </ShareModal>
          </Space>
        )}

        <Spin spinning={false} className='d-100'>
          {user?.id ? (
            <Row gutter={16} className='mt-32 d-100'>
              <Col md={24} xs={24} className='d-center t-center m-0'>
                <Avatar size={80} user={user} />
                <TagLevel user={user} />
                <Title level={3}>{user.name}</Title>
                <Text style={{ color: '#808080;' }}>
                  {user.profile?.personalStatement}
                </Text>
                <Row className='mt-24  d-100' style={{ marginBottom: 0 }}>
                  <Col md={12} xs={24} className='m-0'>
                    <i className='wc-icon icon-ic_company' />
                    <Text> {user.profile?.jobRole?.name}</Text>
                  </Col>
                  <Col md={12} xs={24} className='m-0'>
                    <i className='wc-icon icon-ic_mentor' />
                    <Text> {user.profile?.jobFunction?.name}</Text>
                  </Col>
                </Row>
                {!!user.profile?.webLink && (
                  <Row md={24} xs={24} className='row row m-24'>
                    <i className='wc-icon icon-ic_link mr-8' />
                    <a
                      href={user.profile?.webLink}
                      target='_blank'
                      type='link btn-normal'
                      className=''
                      style={{
                        color: '#0A66C2',
                      }}
                      rel='noreferrer'>
                      {user.profile?.webLink}
                    </a>
                  </Row>
                )}
              </Col>
              {!!user.profile?.intro && (
                <Row className='user-modal-intro'>
                  <Text>{user.profile?.intro}</Text>
                </Row>
              )}
            </Row>
          ) : (
            <Row className='text-center d-justify-center'>
              <Skeleton.Avatar
                size={86}
                style={{ marginTop: 32, marginBottom: 32 }}
              />
              <Skeleton active />
            </Row>
          )}
        </Spin>
        {reportModalVisible && (
          <Modal
            title='REPORT'
            open={true}
            width={600}
            onCancel={() => setModalUserId(null)}
            destroyOnClose
            footer={null}
            closeIcon={
              <i className='wc-icon icon-ic_close' style={{ fontSize: 24 }} />
            }>
            <div>
              <Form
                form={fromReport}
                onFinish={onFinishReport}
                initialValues={{
                  reason: 'Account is suspicious/scam',
                }}>
                {
                  <Row className='d-flex-start'>
                    <Col xs={24}>
                      <Text strong className='d-flex mt-32'>
                        Reason for reporting:
                      </Text>
                    </Col>
                    <Col xs={24}>
                      <Form.Item name='reason' required={true}>
                        <Radio.Group
                          value={reason}
                          onChange={(e) => {
                            setReason(e.target.value);
                          }}>
                          {[
                            `Account is suspicious/scam`,
                            `Account is being hacked`,
                            `User is abusive`,
                            'Others:',
                          ].map((x) => (
                            <Radio
                              key={x}
                              value={x}
                              className='d-flex d-100 mt-16'>
                              {x}
                            </Radio>
                          ))}
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                }
                <Form.Item name='otherReason'>
                  <Input
                    placeholder='Enter reason'
                    disabled={fromReport.getFieldValue('reason') !== 'Others:'}
                  />
                </Form.Item>
                <Col xs={24}>
                  <Text strong>Image</Text>
                </Col>
                <Col xs={24}>
                  <div className='info-upload-content d-flex-start mt-8 ml-0 mb-8'>
                    <div className='info-upload-btn-group'>
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <label htmlFor='icon-button-file'>
                          <Button
                            danger
                            className='ml--12 pl-32 pr-32'
                            style={{
                              height: 48,
                              paddingLeft: 32,
                              paddingRight: 32,
                            }}
                            icon={<UploadOutlined />}>
                            UPLOAD IMAGE
                          </Button>
                        </label>
                      </div>
                    </div>
                  </div>
                  {files.map((x) => (
                    <Col xs={24} key={x} className='mb-0'>
                      <Space>
                        <span className='wc-icon icon-ic_image text-primary' />
                        <Text className='text-primary'>
                          {x.substring(x.length - 25)}
                        </Text>
                        <Button
                          type='link'
                          className='btn-inactive pl-4'
                          onClick={() =>
                            setFiles(files.filter((f) => f !== x))
                          }>
                          <span className='wc-icon icon-ic_trash color-unset' />
                        </Button>
                      </Space>
                    </Col>
                  ))}
                </Col>
                <Col xs={24} className='mb-32 mt-24 d-flex-center'>
                  <Button type='primary' htmlType='submit'>
                    SEND REPORT
                  </Button>
                </Col>
              </Form>
            </div>
          </Modal>
        )}
      </Modal>
    </>
  );
};

export default UserModal;
